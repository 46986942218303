import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './contact.css';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:yousreouali.youslab@gmail.com?subject=Message from ${name}&body=${encodeURIComponent(message)}%0D%0A%0D%0AFrom:%20${name}%20(${email})`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="contact-container">
      <Helmet>
        <title>YousLab: Contact me</title>
      </Helmet>

      <h1>Contact Me</h1>
      <div className="contact-content">
        <div className="contact-info">
          <h2>Get in Touch</h2>
          <p>If you have any questions or just want to say hi, feel free to reach out!</p>
          <ul>
            <li>
              <a href="mailto:yousreouali.youslab@gmail.com" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-envelope"></i> yousreouali.youslab@gmail.com
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/yousre-ouali-4494861b6/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i> LinkedIn
              </a>
            </li>
            <li>
              <a href="https://github.com/Yousriiiii" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github"></i> GitHub
              </a>
            </li>
          </ul>
        </div>

        <div className="contact-form">
          <h2>Send a Message</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="no-resize"
            ></textarea>

            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
