// import packages
import React from 'react';

// import data, images and other things
import './footer.css';
import github_logo from './github.png'
import linkedin_logo from './linkedin.png'


function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="footer-content">
          <div>
            <p>&copy; 2024 Yousre Ouali. All rights reserved.</p>
          </div>
          <div className="footer-links">
            <a href="https://www.linkedin.com/in/yousre-ouali-4494861b6/" target="_blank" rel="noopener noreferrer">
              <img src={linkedin_logo} alt="LinkedIn" />
            </a>
            <a href="https://github.com/Yousriiiii" target="_blank" rel="noopener noreferrer">
              <img src={github_logo} alt="GitHub" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
