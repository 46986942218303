
export async function getProjects() {
    try {
      const response = await fetch("/projects_data.json");
      if (!response.ok) {
        throw new Error('Network response was not ok' + response.statusText);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching the projects:', error);
      throw error;
    }
  }
  