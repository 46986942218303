// import packages
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';

// import data, images and other things
import animationData from './test.json';
import avatar from './profile.png';

import './home.css';

function Home() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      <Helmet>
        <title>YousLab: Home page</title>
      </Helmet>

      <div className='global-avatar' >
        <img src={avatar} alt="React My avatar" className="avatar-img" />
        <div className='avatar-text'>
          <h1>Welcome to my portfolio, a place where I introduce myself and showcase <span className="rainbow-text">my creativity</span></h1>
        </div>
      </div>

      {/* <Lottie
        options={defaultOptions}
        height={400}
        width={400}
      /> */}

      <div className="about-me-section">
        <h1>About me</h1>
        <div className="about-me-text">
          <p>
            Hello! I'm Yousre Ouali, a passionate software developer dedicated to creating innovative and practical solutions. I graduated in 2024 as an industrial software engineer from HE2B ISIB in Belgium. During my studies, I completed two internships: one at Alstom and the other at Irisib. I also worked on numerous projects during my studies. You can find some of them by clicking <Link to="/projects">here</Link>. These experiences have equipped me with a robust skill set in both individual and collaborative work environments.
          </p>
          <p>
            When I'm bored, I enjoy challenging myself with questions that have piqued my curiosity. This process often leads me to explore and develop new technologies, which I thoroughly enjoy! Sometimes, I also embark on projects to deepen my understanding of these technologies. I'm always open to new opportunities and collaborations, so feel free to reach out!
          </p>
          <p>
            Looking ahead, I aspire to become a leading expert in software development, particularly in the fields of artificial intelligence and cybersecurity. I am eager to contribute to cutting-edge projects that have a positive impact on society and its safety. My goal is to continuously grow and innovate, pushing the boundaries of what technology can achieve.
          </p>
        </div>
      </div>

      <div className="about-me-section">
        <h1>Why This Portfolio</h1>
        <div className="about-me-text">
          <p>
            Creating this portfolio as a website allows me to showcase my skills and projects in a dynamic and interactive way. Unlike a traditional resume or static portfolio, a web-based portfolio provides a platform to demonstrate my proficiency in web development and design.
          </p>
          <p>
            This site serves as a comprehensive representation of my work, where visitors can explore my projects, read about my experiences, and see firsthand the quality and creativity I bring to my work. It’s an opportunity to make a lasting impression on potential employers.
          </p>
          <p>
            Additionally, maintaining a web portfolio ensures that my latest work is always accessible and up-to-date. It reflects my commitment to continuous learning and adaptation in the fast-evolving field of technology. By regularly updating this site, I can share new projects, skills, and achievements as they happen.
          </p>
          <p>
            Ultimately, this portfolio is a testament to my passion for software development and my desire to contribute to meaningful and innovative projects. It’s a gateway for new opportunities and collaborations, and I invite you to explore my work and connect with me.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
