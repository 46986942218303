import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// NAVIGATION
import Navigation from '../view/navigation/navigation';

// FOOTER
import Footer from '../view/footer/footer';

// ALL CORE VIEWS
import Home from '../view/home/home';
import Projects from '../view/projects/projects';
import Skills from '../view/skills/skills';
import Contact from '../view/contact/contact';
import NotFound from '../view/NotFound/notFound';

function Controller() {
  return (
    <BrowserRouter>
      {/* ADD NAVIGATION, MORE READABLE */}
      <Navigation />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Controller;
